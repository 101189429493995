"use strict";

import $          from 'jquery';

export default class Share {
    constructor () {
        let it = this;

        it.$link = $('.sharing__btn');
    }


    /**
     * Init
     */
    init () {
        let it = this;

        it.$link.on('click', function (e) {
            e.preventDefault();

            let social = $(this).attr('data-share');
            let url = window.location.hostname + window.location.pathname;

            switch (social) {
                case 'vk':
                    console.log(`http://vk.com/share.php?url=https://${url}&noparse=true`);
                    window.open(`https://vk.com/share.php?url=http://${url}`, 'Поделиться', 'width=650,height=500');
                    break;
                case 'fb':
                    console.log(`https://www.facebook.com/sharer/sharer.php?u=https://${url}`);
                    window.open(`https://www.facebook.com/sharer/sharer.php?u=http://${url}`, 'Поделиться', 'width=650,height=300');
                    break;
                case 'ok':
                    console.log(`https://connect.ok.ru/offer?url=https://${url}`);
                    window.open(`https://connect.ok.ru/offer?url=http://${url}`, 'Поделиться', 'width=650,height=400');
                    break;
                // case 'tw':
                //     window.open(`https://twitter.com/intent/tweet?text=${it.pageDescription}`, 'Поделиться', 'width=650,height=300');
                //     break;
                default:
                    console.error('No sharing');
            }
        });
    }
}