"use strict";

import $          from 'jquery';
import device     from 'device';

import Platform from 'platform';

export default class Video {
    constructor () {
        let it = this;

        it.$button = {
            parent: $('.button'),
            link: $('.button a')
        };
        it.$triggers = $('.video__triggers');
        it.$triggerBtn = $('.triggers__item');

        it.$restart = $('._modal-restart');

        it.$videos = $('.video__player-videos');
        it.video = document.getElementById('video-player');

        it.step = 0;

        it.timings = {
            1 : 10,
            2 : 24,
            3 : 15,
        };

        it.randomModalMessage = () => {
            let modal_messages = [
                {
                    title: "Практикант",
                    text: "В&nbsp;борьбе с&nbsp;микробами вам еще многому нужно научится, ведь стафилококки ставят вам палочки в&nbsp;колеса."
                },
                {
                    title: "Дилетант",
                    text: "Вы&nbsp;трудолюбивы и&nbsp;настойчивы, но&nbsp;микробы убегают сквозь пальцы. Вам нужно освоить азы борьбы с&nbsp;микробами."
                },
                {
                    title: "Студент",
                    text: "Вы&nbsp;неплохо справились, микробы уже боятся вас. Однако вам еще есть чему поучиться у&nbsp;профессоров."
                },
            ]

            let random = Math.floor(Math.random() * modal_messages.length);

            return modal_messages[random];
        }

        it.randomReagent = () => {
            let reagents = [
                'acid',
                'alcohol',
                'cola',
                'powder',
                'sodium',
                'water',
                'whiteness'
            ];

            let random = Math.floor(Math.random() * (6 + 1));

            return reagents[random];
        };
    }


    /**
     * Init
     */
    init () {
        let it = this;


        it.centeredVideo();
        $(window).on('resize', function () {
            it.centeredVideo();
        });


        it.$restart.on('click', function () {
            it.restart();
        });


        if ( device.mobile() || Platform.name === 'Safari' ) {
            it.$button.link.on('click', function () {
                if ( it.$button.parent.hasClass('_start') ) {
                    it.video.play();
                    it.showScientistHint();
                    it.$button.parent.removeClass('_show');

                    setTimeout(() => {
                        it.$button.parent.removeClass('_start');
                    }, 1000);
                }
            });
        } else {
            it.$button.parent.removeClass('_start').removeClass('_show');
            it.video.play();
            it.showScientistHint();
        }

        it.nextChoose();
    }


    /**
     * Next Choose Video
     */
    nextChoose () {
        let it = this;

        if ( it.step === 3 ) {

            let startInterval = setInterval(() => {
                if (it.video.currentTime > it.video.duration - 1) {
                    clearInterval(startInterval);

                    it.video.pause();
                    it.openPopup();
                }
            }, 500);

            return false
        }

        let startInterval = setInterval(() => {
            if ( it.video.currentTime > it.video.duration - it.video.duration/2 ) {
                clearInterval(startInterval);

                it.step++;

                // функция запуска следующего видео
                let nextVideo = () => {
                    $(it.nextVideo).css('opacity' , 1);
                    it.video.pause();
                    it.nextVideo.play();

                    $(it.video).remove();
                    it.video = document.getElementById('video-player-waiting-' + it.step);

                    it.$button.parent.removeClass('_show');

                    it.nextWaiting();

                    // удаляем слежку за кликом
                    it.$button.link.unbind('click');

                    // если шаг первый, то меняем кнопку с "начать" на "продолжить"
                    if ( it.step === 1 ) {
                        setTimeout(() => {
                            it.$button.parent.addClass('_cont');
                        }, 1000);
                    }
                };


                // показ кнопки
                // let showBtnInterval = setInterval(() => {
                //     if ( it.video.currentTime > it.video.duration - it.video.duration/2 ) {
                //         clearInterval(showBtnInterval);
                //         it.$button.parent.addClass('_show');
                //     }
                // }, 500);


                // добавление следующего видео на страницу
                let choose = device.mobile() ? `
                    <video playsinline x-webkit-airplay="deny" preload="auto" id="video-player-waiting-${it.step}">
                      <source src="/files/mobile/waiting-${it.step}.mp4" type="video/mp4">
                      <source src="/files/mobile/waiting-${it.step}.ogv" type="video/ogg">
                    </video>
                ` : `
                    <video playsinline x-webkit-airplay="deny" preload="auto" id="video-player-waiting-${it.step}">
                      <source src="/files/waiting-${it.step}.mp4" type="video/mp4">
                      <source src="/files/waiting-${it.step}.ogv" type="video/ogg">
                    </video>
                `;
                it.$videos.append(choose);
                it.nextVideo = document.getElementById('video-player-waiting-' + it.step);


                // если кнопка не была нажата, видео запускается само
                // на мобилах видео просто стопится
                let nextInterval = setInterval(() => {

                    let time_to_blur = it.video.duration - 2;
                    if(it.step === 1) {
                        time_to_blur = it.video.duration - 4.5;
                    }

                    if ( it.video.currentTime > time_to_blur ) {

                        clearInterval(nextInterval);
                        it.video.pause();
                        it.$button.parent.addClass('_show');
                        it.$videos.addClass('_blured');

                        // if ( device.mobile() || Platform.name === 'Safari' ) {
                        //     clearInterval(nextInterval);
                        //     it.video.pause();
                        // } else {
                        //     clearInterval(nextInterval);
                        //     nextVideo();
                        // }
                    }
                }, 200);

                // запуск следующего видео по клику и сброс интервала автозапуска
                it.$button.link.on('click', function () {
                    clearInterval(nextInterval);
                    it.$videos.removeClass('_blured');
                    nextVideo();
                });
            }
        }, 1000);
    }


    /**
     * Next Waiting Video
     */
    nextWaiting () {
        let it = this;

        let startInterval = setInterval(() => {
            if ( it.video.currentTime > 5 ) {
                clearInterval(startInterval);
                let blinkInterval;

                // выбор реагента
                let reagent = it.randomReagent();


                // функция запуска следующего видео
                let nextVideo = () => {
                    clearInterval(blinkInterval);

                    // добавление следующего видео на страницу
                    let choose = device.mobile() ? `
                        <video playsinline x-webkit-airplay="deny" preload="auto" id="video-player-choose-${it.step}">
                          <source src="/files/mobile/choose-${it.step}-${reagent}.mp4" type="video/mp4">
                          <source src="/files/mobile/choose-${it.step}-${reagent}.ogv" type="video/ogg">
                        </video>
                    ` : `
                        <video playsinline x-webkit-airplay="deny" preload="auto" id="video-player-choose-${it.step}">
                          <source src="/files/choose-${it.step}-${reagent}.mp4" type="video/mp4">
                          <source src="/files/choose-${it.step}-${reagent}.ogv" type="video/ogg">
                        </video>
                    `;
                    it.$videos.append(choose);
                    it.nextVideo = document.getElementById('video-player-choose-' + it.step);

                    $('.triggers__item').not($('.triggers__item[data-reagent='+ reagent +']')).addClass('_hide');

                    setTimeout(() => {
                        $(it.nextVideo).css('opacity' , 1);
                        it.video.pause();
                        it.nextVideo.play();

                        $(it.video).remove();
                        it.video = document.getElementById('video-player-choose-' + it.step);

                        it.$triggers.removeClass('_show');
                        setTimeout(() => {
                            $('.triggers__item').removeClass('_hide');
                        }, 1000);

                        // запускаем подсказку и дисклеймеры
                        it.hint(it.video, reagent);
                        it.disclaimer(it.video, reagent);

                        it.nextChoose();

                        // удаляем слежку за кликом
                        it.$triggerBtn.unbind('click');
                    }, 1000);
                };


                // показ триггеров
                let showTriggerInterval = setInterval(() => {
                    if ( it.video.currentTime > it.timings[it.step] ) {
                        clearInterval(showTriggerInterval);
                        it.$triggers.addClass('_show');

                        blinkInterval = setInterval(() => {
                            it.blink();
                        }, 10000);
                    }
                }, 500);



                // если кнопка не была нажата, видео запускается само
                // на мобилах видео просто стопится
                let nextInterval = setInterval(() => {
                    if ( it.video.currentTime > it.video.duration - 2 ) {
                        if ( device.mobile() || Platform.name === 'Safari' ) {
                            clearInterval(nextInterval);
                            it.video.pause();
                        } else {
                            clearInterval(nextInterval);
                            nextVideo();
                        }
                    }
                }, 500);

                // запуск следующего видео по клику и сброс интервала автозапуска
                it.$triggerBtn.on('click', function () {
                    clearInterval(nextInterval);
                    reagent = $(this).data('reagent');
                    nextVideo();
                });
            }
        }, 1000);
    }

    showScientistHint() {
        let it = this;
        let $hint = $(".scientist-hint");
        let time_to_start = 4000;
        let duration = 4500;

        setTimeout(() => {
            $hint.addClass("_show");

            setTimeout(() => {
                $hint.removeClass("_show");
            }, duration)
        }, time_to_start)
    }

    hint (video, reagent) {
        let it = this;
        let $parent = $('.hint');
        let $hint = $('.hint__text');
        let text, time, showing;

        if ( it.step === 1 ) {
            text = 'Справа мы видим размытые пятна, что говорит о гибели микроорганизмов. А слева они продолжают движение как ни в чем не бывало.';
            switch(reagent) {
                case "alcohol":      time = 11; showing = 21000; break;
                case "sodium":       time = 13; showing = 19000; break;
                case "cola":         time = 13.8; showing = 19000; break;
                case "water":        time = 13; showing = 19000; break;
                case "whiteness":    time = 10; showing = 21000; break;
                case "acid":         time = 14; showing = 18000; break;
                case "powder":       time = 11; showing = 22000; break;
                default:             time = 13; showing = 25000;
            }
            // time = 13;
            // showing = 25000;
        } else if ( it.step === 2 ) {
            text = 'Вода стала прозрачной. Средство обладает антибактериальным действием. ';
            time = 12;
            showing = 13000;
        } else if ( it.step === 3 ) {
            text = 'Пена появляется, если микроорганизмы в колбе еще живы.';
            time = 13.5;
            showing = 16000;
        }

        let interval = setInterval(() => {
            if ( video.currentTime >= time ) {
                clearInterval(interval);
                $hint.html(text);
                $parent.addClass('_show');

                setTimeout(() => {
                    $parent.removeClass('_show');
                }, showing)
            }
        }, 100);
    }



    disclaimer (video, reagent) {
        let it = this;
        let $parent = $('.disclaimer');
        let $hint = $('.disclaimer__text');
        let notRepeat = 'Не повторяйте дома';
        // let demonstrate = 'Художественная демонстрация действия продукта';
        let unilever = 'Эффективность Domestos (антимикробное действие за 15 минут) подтверждена инструментальными тестами (Италия, 2016)';
        let time, showing, timeRepeat, demonstrate, reagentHint, reagentHintShowing;

        if(reagent == 'sodium') {
            reagentHint = 'Пищевая сода. Перед применением ознакомиться с информацией о продукте на этикетке.';
        } else if(reagent == 'powder') {
            reagentHint = 'Чистящий порошок без антибактериального компонента.';
        } else if(reagent == 'whiteness') {
            reagentHint = 'Одно из широко распространенных хлорсодержащих чистящих средств в России, жидкое как вода.';
        }

        if ( it.step === 1 ) {
            time = 35;
            showing = 5000;
            timeRepeat = 13000;
            demonstrate = 'Художественная демонстрация антибактериального эффекта продукта';
        } else if ( it.step === 2 ) {
            time = 25;
            showing = 5000;
            timeRepeat = 5000;
            demonstrate = 'Художественная демонстрация обесцвечивания окрашенной жидкости хлорсодержащим антибактериальным средством';
        } else if ( it.step === 3 ) {
            time = 29;
            showing = 5000;
            timeRepeat = 5000;
            demonstrate = 'Художественная демонстрация антимикробного эффекта продукта';
        }

        if(reagentHint) {
            $hint.html(reagentHint);
            reagentHintShowing = showing;
        } else {
            $hint.html(demonstrate);
            reagentHintShowing = 0;
        }
        $parent.addClass('_show');

        setTimeout(() => {
            $hint.html(demonstrate);
        }, showing);

        setTimeout(() => {
            $parent.removeClass('_show');

            setTimeout(() => {
                $hint.html(notRepeat);
                $parent.addClass('_show');

                setTimeout(() => {
                    $parent.removeClass('_show');

                    let interval = setInterval(() => {
                        if ( video.currentTime >= time ) {
                            clearInterval(interval);

                            $hint.html(unilever);
                            $parent.addClass('_show');

                            video.addEventListener('pause', function () {
                                $parent.removeClass('_show');
                            });
                        }
                    }, 100);
                }, showing)
            }, 100);
        }, timeRepeat + reagentHintShowing)
    }



    /**
     * Restart
     */
    restart () {
        let it = this;

        it.step = 0;

        // добавление следующего видео на страницу
        let choose = `
                    <video preload="auto" id="video-player-start">
                      <source src="/files/start.mp4" type="video/mp4">
                      <source src="/files/start.ogv" type="video/ogg">
                    </video>
                `;
        it.$videos.append(choose);
        it.nextVideo = document.getElementById('video-player-start');


        // запуск сука
        $(it.nextVideo).css('opacity' , 1);
        it.video.pause();
        it.nextVideo.play();

        $(it.video).remove();
        it.video = document.getElementById('video-player-start');

        it.nextChoose();

        // удаляем слежку за кликом
        it.$button.link.unbind('click');

        // если шаг первый, то меняем кнопку с "начать" на "продолжить"
        it.$button.parent.removeClass('_cont');

        it.closePopup();
    }


    /**
     * Open Popup
     */
    openPopup () {
        let it = this;

        const random_result = it.randomModalMessage();

        $(".modal__title").html(random_result.title);
        $(".modal__text").html(random_result.text);

        $('.body').addClass('_overlayed');
        $('.overlay').addClass('_show');
    }


    /**
     * Blink
     */
    blink () {
        console.log('blink');
        let it = this;
        let length = it.$triggerBtn.length;
        let delay = 200;
        let delayAnim = 320;

        for (let i = 0; i < length; i++) {
            setTimeout(() => {
                $(it.$triggerBtn[i]).addClass('_blink');

                setTimeout(() => {
                    $(it.$triggerBtn[i]).removeClass('_blink');
                }, delayAnim);
            }, delay*i);
        }
    }


    /**
     * Close Popup
     */
    closePopup () {
        let it = this;
        $('.body').removeClass('_overlayed');
        $('.overlay').removeClass('_show');
    }


    /**
     * Centered Video
     */
    centeredVideo () {
        let it = this;


        return new Promise((resolve, reject) => {
            let width = $(window).width();
            let height = $(window).height();
            let $player = $('.video__player');

            let videoProportion = 1920/1080;
            let windowProportion = width/height;

            if ( videoProportion > windowProportion ) {
                $player.width(width).height(width/videoProportion);
            } else {
                $player.width(height*videoProportion).height(height);
            }

            resolve();
        });
    }
}