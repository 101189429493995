"use strict";

/* Vendor */

import $ from 'jquery';


/* Components */
import Preloader from './components/preloader';
import Video from './components/video';
import Share from './components/sharing';
import Scream from 'scream';
import Brim from 'brim';
import Platform from 'platform';


/* App */

const video = new Video();
const share = new Share();

const preloader = new Preloader(false, function () {
    console.log('Страница загружена');
});


// Клик по документу (нужен для отлова общих кликов по элементам)
$(document).on('click', function (e) {
    let $target = $(e.target);

    // блокировка клика по пустым ссылкам
    if ( $target.attr('href') === '#' || $target.closest('a').attr('href') === '#' ) {
        e.preventDefault();

        // проверка ссылки на принадлежность к модальным окнам (должнен быть $_ в начале)
    } else if ( /^\$_.+$/.test($target.attr('href')) ) {
        e.preventDefault();
        modal.open($target.attr('href').substring(2));

        // закрытие модальных окон по overlay
    } else if ( $target.hasClass('overlay') || $target.hasClass('overlay__inner') ) {
        modal.close();
    }
});

window.onload = () => {

    preloader.hide();
    video.init();
    share.init();


    $('.triggers__item').on('mouseover', function () {
        if ( !$(this).hasClass('_hover') ) {
            $(this).addClass('_hover');
            let reagent = {
                name: $(this).data('reagent'),
                title: $(this).data('title')
            };

            $('.video__triggers-tooltip').addClass('_light').text(reagent.title);
        }
    });

    $('.triggers__item').on('mouseleave', function () {
        if ( $(this).hasClass('_hover') ) {
            $(this).removeClass('_hover');

            $('.video__triggers-tooltip').removeClass('_light').text('Выберите средство');
        }
    });



    // функционал для прятанья интерфейса браузера на iOS
    // if (Platform.os.family == 'iOS' && parseInt(Platform.os.version, 10) > 8 || Platform.ua.indexOf('like Mac OS X') != -1) {
    //     let scream = Scream({
    //         width: {
    //             portrait: 320,
    //             landscape: 568
    //         }
    //     });
    //
    //     let brim = Brim({
    //         viewport: scream
    //     });
    //
    //     brim.on('viewchange', function (e) {
    //         console.log(e.viewName);
    //         document.body.className = e.viewName;
    //     });
    // } else {
    //
    //     Brim.on('viewchange', function (e) {
    //         console.log(e.viewName);
    //         document.body.className = e.viewName;
    //     });
    // }


    if ( $(window).width() > $(window).height() ) {
        $('.gag').addClass('_landscape').removeClass('_portrait');
    } else if ( $(window).width() < $(window).height() ) {
        $('.gag').addClass('_portrait').removeClass('_landscape');
    } else {
        console.log('quatro');
    }
    window.addEventListener("orientationchange", function(e) {
        setTimeout(() => {
            if ( $(window).width() > $(window).height() ) {
                $('.gag').addClass('_landscape').removeClass('_portrait');
            } else if ( $(window).width() < $(window).height() ) {
                $('.gag').addClass('_portrait').removeClass('_landscape');
            } else {
                console.log('quatro');
            }
        }, 300);
    }, false);
};