"use strict";

import $          from 'jquery';

export default class Preloader {
    constructor (load, callback) {
        let it = this;

        it.$preloader = $('.preloader');
        it.$images = $('img[data-src]');
        it.callback = callback;

        it.load = load;
        it.init();
    }


    /**
     * Init
     */
    init () {
        let it = this;

        if ( it.$images.length ) {
            it.loadImages().then(_ => {
                console.log('Изображения загружены');
            }).catch(err => {

            });
        }
    }


    /**
     * Hide Preloader
     */
    hide () {
        let it = this;

        it.$preloader.addClass('_hide');
        setTimeout(() => {
            it.$preloader.hide();
        }, 350);

        it.callback();
    }


    /**
     * Load Images
     */
    loadImages () {
        let it = this;
        let promises = [];
        let errorLoad = [];

        return new Promise((resolve, reject) => {

            for ( let i = it.$images.length; i > 0; i-- ) {
                promises.push(new Promise((resolve, reject) => {

                    let image = new Image();
                    let $img = it.$images.eq(i-1);

                    image.onload = () => {
                        $img.attr('src', image.src);
                        $img.addClass('_isinit');
                        resolve();
                    };
                    image.onerror = () => {
                        errorLoad.push(image.src);
                        $img.addClass('_notinit');
                        reject();
                    };
                    image.src = $img.data('src');
                }));
            }


            Promise.all(promises).then(_ => {
                resolve();
            }).catch(_ => {
                console.info('Некоторые изображения не были загружены', errorLoad);
                reject();
            });
        });
    }
}